@import-normalize;

/* roboto-300 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/Roboto/roboto-300.woff2") format("woff2");
}
/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/Roboto/roboto-regular.woff2") format("woff2");
}
/* roboto-500 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/Roboto/roboto-500.woff2") format("woff2");
}
/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/Roboto/roboto-700.woff2") format("woff2");
}

* {
  box-sizing: border-box;
}

html {
  font-size: 100%;
}

body {
  font-family: "roboto", "-apple-system", "Arial", "sans-serif";
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

a {
  color: #000;
  text-decoration: underline;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  clip-path: polygon(0px 0px 0px 0px 0px 0px);
  border: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

